import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { COLORS, commonStyles } from '../../constants'

function Footer(props) {
    const { classes } = props;

    return (
        <div className={classes.footer}>
            <div className={classes.footerContent}>
                <div className={classes.copyNormal} style={{ display: "flex", fontSize: 13, color: COLORS.PLACEHOLDERGREY }}>© 2020 Street FC</div>
                <div className={classes.copyNormal} style={{ display: "flex", fontSize: 13, color: COLORS.GREY }}>
                    <Link className={classes.link} to="/privacy" target="_blank">Privacy Policy</Link>{" "}
                    &nbsp; | &nbsp;
                    <Link className={classes.link} to="/terms" target="_blank">Terms and Conditions</Link>
                    &nbsp; | &nbsp;
                    <Link className={classes.link} to="/rules" target="_blank">Rules</Link>
                </div>
            </div>
        </div>
    )
}

export default withStyles(commonStyles, { withTheme: true })(Footer)
