import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from "react-router-dom";

import Invite from "./components/Invite";
import {
    Discord,
} from "./components/Discord"
import {
    Liability,
    Privacy,
    Rules,
    Terms,
} from "./components/Legal"
import Game from "./components/Game";

const New = () => {
    return <iframe
      style={{ width: "100vw", height: "100vh", border: '0' }}
      title="StreetFC.city"
      src="https://raspberry-kazoo-9p6l.squarespace.com"
    />
}

class App extends Component {
  render() {
    return (
        <Router>
          <Switch>
            <Route path="/" exact component={New} />
            <Route path="/invite" component={Invite} />
            <Route path="/game/view/:id?" render={ ({ match }) => {
                // const scope = match.params.scope || "public";
                // const id = match.params.id ? `/${match.params.id}`: '';
                // const dataUrl = `${serverUrl}/api/v1/dashboard/sections/${scope}${id}`;
                // return <Game serverUrl={ serverUrl } dataUrl={ dataUrl } />
                return <Game />
            } } />
            <Route path="/game/:id?" render={ ({ match }) => {
                // const scope = match.params.scope || "public";
                // const id = match.params.id ? `/${match.params.id}`: '';
                // const dataUrl = `${serverUrl}/api/v1/dashboard/sections/${scope}${id}`;
                // return <Game serverUrl={ serverUrl } dataUrl={ dataUrl } />
                return <Game />
            } } />
            <Route path="/liability" component={Liability} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/rules" component={Rules} />
            <Route path="/discord/id/:userId" component={Discord} />
          </Switch>
        </Router>
    );
  }
}

export default withRouter(App);
