import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import firebase from "../../firebase";
import { withStyles } from "@material-ui/core/styles";

import { Alert } from '@material-ui/lab';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import User from '../../models/User'
import Country from '../../models/Country'
import SpinnerButton from "../common/SpinnerButton";

import { commonStyles } from '../../constants'
const PHONE_NUMBER_PATTERN = '[-\\d\\s\\.\\(\\)]+?';

const countryNames = require("../../data/names.json");
const countryPhones = require("../../data/phone.json");
const countries = Object
    .keys(countryNames)
    // TODO: Remote config...
    .filter((key) => ['US', 'CA', 'MX'].includes(key))
    .map((key) => new Country(key, countryNames[key], countryPhones[key]))
    .sort((a, b) => a.name.localeCompare(b.name));
// console.log('countries', countries)

const defaultPhoneNumber = process.env.REACT_APP_PHONE
const defaultVerificationCode = process.env.REACT_APP_CODE

const Discord = ({ classes }) => {
    const defaultCountry = countries.find(country => country.code === "US") || new Country("US", "United States", "1");

    const { userId } = useParams();
    const [open, setOpen] = useState(false)
    const [country, setCountry] = useState(defaultCountry);
    const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber);
    const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false);
    const [confirmationResult, setConfirmationResult] = useState(undefined);
    const [working, setWorking] = useState(false);
    const [error, setError] = useState('');
    const [isComplete, setIsComplete] = useState(false);
    const [verificationCode, setVerificationCode] = useState(defaultVerificationCode);
    const [verifier, setVerifier] = useState(undefined);

    useEffect(() => {
        // firebase.auth().settings.appVerificationDisabledForTesting = true;
        setVerifier(
            new firebase.auth.RecaptchaVerifier('recaptcha', {
                size: 'invisible',
                callback: (response) => {
                    console.log('captcha solved');
                },
            })
        );
    }, []);

    const phoneUpdated = (event) => {
        setPhoneNumber(event.target.value);
    }

    const verificationCodeUpdated = (event) => {
        setVerificationCode(event.target.value);
    }

    const submitPhoneNumber = async () => {
        try {
            setWorking(true);
            const result = await firebase
                .auth()
                .signInWithPhoneNumber(`+${country.phone}${phoneNumber}`, verifier);
            setConfirmationResult(result);
            setPhoneNumberSubmitted(true);
            setError('');
        } catch (err) {
            console.log(err);
            setError(err.message);
            setPhoneNumber('');
            setPhoneNumberSubmitted(false);
        } finally {
            setWorking(false);
        }
    };

    const submitCode = async () => {
        try {
            setWorking(true);
            const result = await confirmationResult?.confirm(verificationCode);
            // console.log('result', result)

            await connect({ firebaseUser: result.user })

            setPhoneNumberSubmitted(false);
            setPhoneNumber('');
            setVerificationCode('');
            setConfirmationResult(undefined);
        } catch (err) {
            console.log(err);
            setError(err.message);
            setVerificationCode('');
        } finally {
            setWorking(false);
        }
    };

    const connect = async ({ firebaseUser }) => {
        try {
            const token = await firebaseUser.getIdToken(true);
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            axios.defaults.headers.common["Content-Type"] = "application/json";

            const user = await User.view(null, firebaseUser.uid)
            
            await User.saveField({
                user,
                field: 'discord',
                value: userId,
            })
            setIsComplete(true)
        } catch (error) {
            console.warn(error)
        }
    }

    const canSubmitPhone = phoneNumber.length >= 9;
    const canSubmitCode = !isNullOrWhitespace(verificationCode);

    // const background = require('../../images/radient.png')
    const background = require('../../images/IMG_1029_50.jpg')

    return (
        <div
            key="background"
            backgroundImage={background}
            style={{
                flexGrow: 1,
                width: '100vw',
                height: '100vh',
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <div
                id="recaptcha"
                key="recaptcha"
            ></div>
            <Dialog
                key="verification"
                open={!isComplete}
                hideBackdrop={true}
            >
                <DialogTitle>Sign In with your Phone Number</DialogTitle>
                <DialogContent>
                    {!isNullOrWhitespace(error) && (
                        <Alert severity="error">{error}</Alert>
                    )}
                    <DialogContentText>
                        {!phoneNumberSubmitted && (
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                            >
                                <Grid item>
                                    <InputLabel 
                                        shrink
                                    >
                                        Country Code
                                    </InputLabel>
                                    <Select
                                        // labelId="demo-simple-select-label"
                                        // id="demo-simple-select"
                                        // value={country.shortLabelMap()}
                                        style={{
                                            width: 80
                                        }}
                                        open={open}
                                        onClose={ () => setOpen(false) }
                                        onOpen={ () => setOpen(true) }
                                        value={country}
                                        onChange={ (event) => {
                                            setCountry(event.target.value)
                                        }  }
                                    >
                                        { countries.map(c =>
                                            <MenuItem value={c}>{ open ? c.longLabelMap() : c.shortLabelMap() }</MenuItem>
                                        ) }
                                    </Select>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        // className={styles['phone-number']}
                                        type="tel"
                                        inputProps={{
                                            pattern: PHONE_NUMBER_PATTERN,
                                        }}
                                        onChange={phoneUpdated}
                                        value={phoneNumber}
                                        label="Phone Number"
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {phoneNumberSubmitted && (
                            <>
                                <Typography
                                    style={{
                                        color: '#000'
                                    }}
                                >
                                    Please enter the verification code sent to your phone
                                </Typography>
                                <TextField
                                    // fullWidth
                                    type="number"
                                    label="Verfication Code"
                                    value={verificationCode}
                                    onChange={verificationCodeUpdated}
                                />
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {!phoneNumberSubmitted && (
                        <SpinnerButton
                            variant="contained"
                            className={classes.button}
                            style={{
                                color: "#fff",
                                backgroundColor: "#CD3195",
                            }}
                            loading={working}
                            id="submit-login"
                            onClick={submitPhoneNumber}
                            disabled={working || !canSubmitPhone}
                        >
                            Sign In
                        </SpinnerButton>
                    )}
                    {phoneNumberSubmitted && (
                        <SpinnerButton
                            variant="contained"
                            className={classes.button}
                            style={{
                                color: "#fff",
                                backgroundColor: "#CD3195",
                            }}
                            loading={working}
                            id="submit-login"
                            onClick={submitCode}
                            disabled={working || !canSubmitCode}
                        >
                            Verify
                        </SpinnerButton>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog
                key="success"
                open={isComplete}
            >
                <DialogTitle>You're all set!</DialogTitle>
                <DialogContent>
                    {!isNullOrWhitespace(error) && (
                        <Alert severity="error">{error}</Alert>
                    )}
                    <DialogContentText>
                        You can now close this window.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const isNullOrWhitespace = (s) => {
    if (s === null || s === undefined) {
        return true;
    }

    if (s.trim().length === 0) {
        return true;
    }

    return false;
};

const styles = theme => {
    const styles = commonStyles(theme)
    return {
        ...styles,
        content: {
            ...styles.content,
            color: '#000'
        },
        p: {
            fontWeight: '400',
            color: '#000'
        },
        a: {
            fontWeight: 'bold',
            color: '#000'
        },
    }
}

export default withStyles(styles, { withTheme: true })(Discord)
