import 'dotenv/config'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { unregister } from './registerServiceWorker';

import "./assets/css/nucleo-icons.css"
// TODO: Remove this design system
// import "./assets/scss/blk-design-system-react.scss?v=1.0.0"
// import "./assets/demo/demo.css"

// console.log(process.env)

ReactDOM.render((
    <BrowserRouter>
        <App />
    </BrowserRouter>
), document.getElementById('root'))

// registerServiceWorker()
unregister();
