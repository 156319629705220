import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { commonStyles } from '../../constants'

const Privacy = ({ classes }) =>
    <div className={classes.root}>
        <main className={classes.content}>
            <h1>Personal Data</h1>
            <p>Your personal information and our use of such information, is governed by our Privacy Policy set forth below.  We urge you to carefully review our Privacy Policy to learn how we handle and safeguard such information.</p>
            
            <h1>Privacy Policy</h1>
            <p>The following sets forth our policy with respect to the treatment of information you provide to us or that we collect from you when you subscribe, visit and/or use the Service (as defined in the Terms and Conditions).  </p>
            
            <h2>1. Scope of Policy</h2>
            <p>You must read this Privacy Policy carefully. This Privacy Policy applies only to information you provide to us online or that we collect from you while you are visiting or using the Service.  It does not apply to information we have obtained through any other means.</p>
            
            <h2>2. Information Collected and Used By Us</h2>
            <p>We compile information you provide directly to us (“Personal Identification Data”), as well as general information concerning all users collected at different points in the use of the Service. General Information does not include Personal Identification Data.</p>
            <p><strong>Personal Identification Data:</strong> To obtain access to certain Information, products and services offered on the Service, we require you to submit to us Personal Identification Data by completing the registration form presented to you when you register. Personal Identification Data includes personal information about you such as your name, title, address, email address, telephone number, fax number, company name, and other basic contact information.</p>
            <p><strong>General Information:</strong>  We use “cookies” to collect General Information about the Street FC Website and its users.  Cookies are computer files containing a unique identifier which is sent to your browser from our Website computer and stored on your computer’s hard drive. We use cookies to recognize you when you return to the Website.  General Information we collect may include, but not be limited to, the page from which the cookie was initially installed, the time and date of each visit you make to the website, and the cookie expiration date, among other things.   Most web browsers are set to accept cookies by default, but you can manually disable this feature on your browser.  Review your browser information or contact your browser provider for further information on how to disable cookies.  Please be aware, however, disabling the cookies on your computer may result in the Website not functioning properly during use.</p>
            
            <h2>3. Information We Collect and How it is Used</h2>
            <p>We use both Personal Identification Data and General Information to communicate with you and to provide you with requested products, services and Information or to offer you additional information, special offers and promotions, from time to time.  We will only send promotional materials, special offers and similar information to you if you consent to such solicitation during the registration process. We also use General Information (without revealing Personal Identification Data) to assist us in creating marketing profiles for our business, to attract and manage advertisers, to expand our company strategically and to audit the usage of the Service.</p>
            <p>It is our policy not to disclose your Personal Identification Data to any unaffiliated third party, our corporate partners or our vendors, without your express consent, except under the following limited circumstances:</p>
            <p>(a) We may, from time to time, hire third parties to perform services to our company relating to our business and we may need to share your Personal Identification Data with these companies to allow them to perform the services for which they were hired.   Under these circumstances, we will only provide Personal Identification Data on an as needed basis and we will prohibit the use of any Personal Identification Data for any purpose other than fulfillment of such party’s contractual obligations to us.</p>
            <p>(b) We may disclose Personal Identification Data to attorneys, private investigators, or law enforcement agencies if we believe you are (i) harming or interfering (or may imminently harm or interfere) Street FC, Inc., users of the Service, or any other person, (ii) violating (either intentionally or unintentionally) our Terms and Conditions or (iii) infringing on any of our legal rights, generally, or the rights of our Third Party Providers.</p>
            <p>(c) We may reveal your Personal Identification Data if we reasonably believe we are required to do so by law.</p>
            <p>(d) We may transfer all Personal Identification Data in connection with the sale of any, all, or substantially all of the assets of Street FC, Inc.</p>
            
            <h2>4. Change, Modifications or Removal of Personal Identification Data</h2>
            <p>If you request changes and/or modifications to your Personal Identification Data, we will use our commercially best efforts to update your Personal Identification Data as soon as reasonably practicable. At your request, we will use our best efforts to remove your Personal Identification Data from our database; however, you should be aware that it may be impossible to completely remove all Personal Identification Data, due to backups and records of deletions.  If you wish to remove your Personal Identification Data from our database, send an email to <a href="mailto:info@streetfc.com" className={classes.link}>info@streetfc.com</a> with the term “Unsubscribe” in the subject line.</p>
            
            <h2>5. Credit Card Information.</h2>
            <p>In order to access the Service, you will be required to provide us with your credit card information.  We do not share your credit card information with any third parties.  To protect the security of your credit card information, we employ security measures which are consistent with industry standards.</p>
            
            <h2>6. Security of Personal Identification Data</h2>
            <p>We have implemented security systems designed to prevent unauthorized disclosure of Personal Identification Data and Credit Card Information.  Because of changing Internet communications and evolving technologies, we cannot guarantee absolute security for this information, and we expressly disclaim any assurance that your Personal Identification Data and Credit Card Information will not be lost, misused, or altered by third parties who, despite our best efforts, obtain unauthorized access.</p>
        </main>
    </div>

const styles = theme => {
    const styles = commonStyles(theme)
    return {
        ...styles,
        content: {
            ...styles.content,
            color: '#fff'
        }
    }
}

export default withStyles(styles, { withTheme: true })(Privacy)
