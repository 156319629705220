import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { commonStyles } from '../../constants'
import Footer from "../common/Footer"
import LogoRoundedStroked from '../../images/logo-rounded-stroked.png'

import AppStore from '../../images/appstore.png'
import GooglePlay from '../../images/googleplay.png'

function Game({ classes, theme }) {
  // console.log(window.location)
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div id="what" className={classes.section}>
          <div
              style={{
                  display: "flex",
                  flexDirection: "column",
                  //   width: "100%",
                  //   height: "521px",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: "url('images/bannerbg.jpg')"
              }}
          >
            <img
                style={{ 
                    // margin: 40, 
                    maxWidth: '90%'
                }} 
                src={LogoRoundedStroked}
                alt="Street FC logo"
                title="Street FC"
            />
            
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: 40, }}>
                <p className={classes.copyNormal} style={{ textAlign: 'center', fontSize: 13, }}>Please install the Street FC app and try that link again.</p>
                <p className={classes.copyNormal} style={{ textAlign: 'center', fontSize: 13, }}><a className={classes.yellow} href={ window.location.href }>{ window.location.href }</a></p>
                <p className={classes.copyNormal} style={{ textAlign: 'center', fontSize: 13, }}>(iOS users: Once installed, press and hold the above link to open with Street FC)</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                <a href="https://streetfc.app.link/q4Ag7EJBTjb"><img style={{ margin: 10 }} src={AppStore} alt="App Store" width="128" height="41" /></a>
                <a href="https://streetfc.app.link/2qF4x6WBTjb"><img style={{ margin: 10 }} src={GooglePlay} alt="Google Play" width="128" height="41" /></a>
            </div>

          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
}

export default withStyles(commonStyles, { withTheme: true })(Game);
