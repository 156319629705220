import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { commonStyles } from '../../constants'

const Liability = ({ classes }) =>
    <div className={classes.root}>
        <main className={classes.content}>
            <h1>RELEASE OF LIABILITY</h1>
            <p>In consideration of being allowed to participate in any way for Street FC, Inc. ("Company") its related events and activities, I acknowledge, appreciate, and agree that: </p>
            <p>1. The risk of injury from the activities involved in this program is significant, including the potential for permanent paralysis and death, and while particular skills, equipment, and personal discipline may reduce this risk, the risk of serious injury does exist; and,</p>
            <p>2. I KNOWINGLY AND FREELY ASSUME ALL SUCH RISKS, both known and unknown, EVEN IF ARISING FROM THE NEGLIGENCE OF THE RELEASEES or others, and assume full responsibility for my participation; and, </p>
            <p>3. I willingly agree to comply with the stated and customary terms and conditions for participation. If, however, I observe any unusual significant hazard during my presence or participation, I will remove myself from participation and bring such to the attention of the Company immediately; and, </p>
            <p>4. I, for myself and on behalf of my heirs, assigns, personal representatives and next of kin, HEREBY RELEASE, INDEMNIFY, AND HOLD HARMLESS Street FC, Inc. their officers, officials, agents and/or employees, other participants, sponsoring agencies, sponsors, advertisers, and, if applicable, owners and lessor's of premises used for the activity ("Releasees"), WITH RESPECT TO ANY AND ALL INJURY, DISABILITY, DEATH, or loss or damage to person or property associated with my presence or participation, WHETHER ARISING FROM THE NEGLIGENCE OF THE RELEASEES OR OTHERWISE, to the fullest extent permitted by law. </p>
            <p>I HAVE READ THIS RELEASE OF LIABILITY AND ASSUMPTION OF RISK AGREEMENT, FULLY UNDERSTAND ITS TERMS, UNDERSTAND THAT I HAVE GIVEN UP SUBSTANTIAL RIGHTS BY SIGNING IT, AND SIGN IT FREELY AND VOLUNTARILY WITHOUT ANY INDUCEMENT. </p>
        </main>
    </div>

const styles = theme => {
    const styles = commonStyles(theme)
    return {
        ...styles,
        content: {
            ...styles.content,
            color: '#fff'
        }
    }
}

export default withStyles(styles, { withTheme: true })(Liability)
