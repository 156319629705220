import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { commonStyles } from '../../constants'

const Rules = ({ classes }) =>
    <div className={classes.root}>
        <main className={classes.content}>
            <h1>RULES</h1>
            <h2 className={ classes.h2 }>FORMAT</h2>
            <p>+ Each "match" is 1 hour long</p>
            <p>+ Each "game" is 4 mins long</p>
            <p>+ We play as many "games" as possible during a 1 hour "match"</p>
            <p>+ Games end after 4 mins or after one team scores 3 goals</p>
            <p>+ Winner stays on</p>
            <p>+ In case of tie, newest team stays on</p>
            <p>+ No goalies</p>
            <p>+ No PKs</p>

            <h2 className={ classes.h2 }>TEAMS</h2>
            <p>+ There are 3 teams per match</p>
            <p>+ Each teams is made up of either 4 of 5 players (depending on court size)</p>
            <p>+ Maximum of 15 players/match</p>

            <h2 className={ classes.h2 }>RULES</h2>
            <p>+ All goals must be scored over halfway line</p>
            <p>+ All stoppages are restarted with kick-ins  (e.g.  corners, out of bounds, handball)</p>
            <p>+ Kick-ins after goals should be taken from between goal posts, no further than 3 ft from goal-line</p>
            <p>+ Handball when ball is going into goal / foul that denies clear goal counts as a goal</p>
            <p>+ Killer Goal: When first goal of a game is scored with a one-touch shot / header from an aerial pass without it touching the ground, the scoring team automatically wins the game</p>

            <h2 className={ classes.h2 }>GEAR</h2>
            <p>+ Goals size range 3x5 to 4x6</p>
            <p>+ Regular size 5 soccer ball is used</p>
            <p>+ Hard court surfaces (basketball court, handball court, parking lot)</p>
            <p>+ Sneakers/flats recommended </p>
            <p>+ Field size between 12x24yrds to 18x30yrds</p>

            <h2 className={ classes.h2 }>CANCELLATION</h2>
            <p>+ There are two cancellation windows:</p>
            <p className={ classes.subrule }>+ Late Cancellation - between 1 and 3 hours before kickoff.</p>
            <p className={ classes.subrule }>+ Last Minute Cancellation - less than 1 hour before Kickoff.</p>
            <p>+ Subscription members will receive a Yellow Card for canceling in either window.</p>
            <p>+ Pay Per Game members, will lose their token in either window and receive a Yellow Card for Late Cancellation and a Red Card for Last Minute Cancellation.</p>
            <p>+ Two Yellow Cards within 30 days triggers a Red Card, which disables reserving a game for 8 days. </p>
            <p>+ "No Shows" will receive a straight Red Card, which disables reserving a game for 8 days.</p>

            <h2 className={ classes.h2 }>MISC</h2>
            <p>+ If there are too few players to make up 3 even-sized teams, or if there is an injury / late arrival / early departure, members of team coming off (after a loss) can join the team coming on</p>
            <p>+ If the number of players drop, the following scenarios can happen:</p>
            <p className={ classes.subrule }>+ 15 Players = 3 teams of 5</p>
            <p className={ classes.subrule }>+ 12 Players = 3 teams of 4</p>
            <p className={ classes.subrule }>+ 10-11 Players = 2 teams of 4, 1 incomplete team who borrows players from losing team</p>
            <p className={ classes.subrule }>+ 8-9 Players = 2 Teams of 4 with 1 player being replaced from losing team by player waiting</p>
            <p className={ classes.subrule }>+ Less than 8 Players = Match is abandoned below 8 players</p>
        </main>
    </div>

const styles = theme => {
    const styles = commonStyles(theme)
    return {
        ...styles,
        content: {
            ...styles.content,
            color: '#fff'
        },
        h2: {
            marginTop: 16,
            marginBotton: 8,
        },
        subrule: {
            marginLeft: 20
        }
    }
}

export default withStyles(styles, { withTheme: true })(Rules)
